// https://pqina.nl/blog/applying-styles-based-on-the-user-scroll-position-with-smart-css/
const debounce = (fn) => {
  let frame

  return (...params) => {
    if (frame) {
      cancelAnimationFrame(frame)
    }

    frame = requestAnimationFrame(() => {
      fn(...params)
    })
  }
}

// === Animate hero and start video ===

document.addEventListener('DOMContentLoaded', () => {
  const mockup = document.getElementById('mockup')
  const heroTitle = document.getElementById('hero-title')
  const heroMessage = document.getElementById('hero-message')
  const heroButtons = document.getElementsByClassName('hero-button')
  const heroVersions = document.getElementsByClassName('hero-version')
  const heroBadges = document.getElementById('hero-badges')

  heroTitle.classList.add('animate-scaleInSlow')

  setTimeout(() => {
    mockup.classList.add('animate-fadeIn')

    setTimeout(() => {
      heroMessage.classList.add('animate-appear')
      for (let i = 0; i < heroButtons.length; i++) {
        heroButtons[i].classList.add('animate-appear')
      }
    }, 1000)

    setTimeout(() => {
      for (let i = 0; i < heroVersions.length; i++) {
        heroVersions[i].classList.add('animate-fadeIn')
      }
      heroBadges.classList.add('animate-fadeIn')
    }, 1500)

    setTimeout(() => {
      mockup.play()
    }, 1500)
  }, 250)
})

// === Hamburger menu ===

const menuBtn = document.getElementById('menu-btn')
const menu = document.getElementById('menu')
const menuItems = document.getElementById('menu-items')

menuBtn.addEventListener('click', navToggle)

function navToggle() {
  menuBtn.classList.toggle('open')
  menu.classList.toggle('-translate-y-full')
  menu.classList.toggle('invisible')
  document.documentElement.classList.toggle('lock-scroll')
}

// close toggle when clicking outside of menu
document.body.addEventListener('click', function (event) {
  if (menu.contains(event.target) && !menuItems.contains(event.target)) {
    navToggle()
  }
})

// close toggle when a menu item is clicked
menuItems.querySelectorAll('a').forEach((item) => {
  item.addEventListener('click', function () {
    if (menu.classList.contains('invisible') === false) {
      navToggle()
    }
  })
})

function isMenuOpen() {
  return menu.classList.contains('invisible') === false
}

// === Auto-hide nav bar ===

const navBar = document.getElementById('header')

const processScroll = () => {
  var scrollY = window.scrollY
  document.documentElement.dataset.scroll = scrollY
  if (!isMenuOpen()) {
    translateElement(navBar, scrollY)
  }
}

var lastScrollY = 0

function translateElement(element, scrollY) {
  const translateNegative = '-translate-y-32'
  const translateZero = 'translate-y-0'
  const scrollThreshold = 5

  if (scrollY < 96) {
    element.classList.remove(translateNegative)
    element.classList.add(translateZero)
  } else if (scrollY > lastScrollY) {
    element.classList.add(translateNegative)
    element.classList.remove(translateZero)
  } else if (lastScrollY - scrollY > scrollThreshold) {
    element.classList.remove(translateNegative)
    element.classList.add(translateZero)
  }

  lastScrollY = scrollY <= 0 ? 0 : scrollY
}

processScroll()
document.addEventListener('scroll', debounce(processScroll), { passive: true })

// === Trigger animations when elements appear ===

const addInitialClassAndObserve = (targets, observer) => {
  targets.forEach((target) => {
    target.classList.add('opacity-0')
    observer.observe(target)
  })
}

const createAnimationObserver = (selector, animationDataAttr, threshold) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting && target.dataset[animationDataAttr]) {
          const animationClass = 'animate-' + target.dataset[animationDataAttr]
          target.classList.add(animationClass)
        }
      })
    },
    { rootMargin: '-0px', threshold }
  )

  const targets = document.querySelectorAll(selector)
  addInitialClassAndObserve(targets, observer)
}

createAnimationObserver('[data-animation]', 'animation', 0.8)
createAnimationObserver('[data-animation-long]', 'animationLong', 0.05)

document.addEventListener('DOMContentLoaded', (event) => {
  document.querySelectorAll('.faq-item dt').forEach((question) => {
    question.addEventListener('click', function () {
      const id = this.nextElementSibling.id // Get the id of the next sibling dd element
      toggleAnswer(id)
    })
  })
})

function toggleAnswer(id) {
  var element = document.getElementById(id)
  var question = element.previousElementSibling // Assuming <dd> comes right after <dt>
  var icon = document.getElementById('toggleIcon' + id.replace('answer', ''))
  var line = icon.querySelector('.toggle-line')

  if (element.classList.contains('hidden')) {
    element.classList.remove('hidden')
    line.classList.add('hidden-line') // Hide the vertical line to make it a minus
    question.classList.add('text-primary-500')
  } else {
    element.classList.add('hidden')
    line.classList.remove('hidden-line') // Show the vertical line to make it a plus
    question.classList.remove('text-primary-500')
  }
}
